import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Link } from "gatsby";
import EnhancedLink from "./EnhancedLink";

export default function MenuRender(arg) {
  let data = "",
    start = ".",
    className = "",
    linkClassName = "",
    maxDepth = 99,
    menu,
    type;
  if (arg && arg.data) data = arg.data;
  if (arg && arg.start) start = arg.start;
  if (arg && arg.depth) maxDepth = arg.depth;

  if (arg && arg.className) className = arg.className;
  if (arg && arg.linkClassName) linkClassName = arg.linkClassName;
  if (arg && arg.type) type = arg.type;

  menu = String(data).trim();
  menu = menu.replace(new RegExp(/\\~/, "gi"), "~");

  if (!menu) {
    return <>Menu Error.</>;
  }

  // convert text to data structure
  let menuStruct = [];
  let menus = [];

  let lines = [];

  if (menu.indexOf("\n") !== -1) {
    lines = menu.split("\n");
  } else {
    lines = menu.split("\r");
  }

  lines.forEach((line) => {
    if (line.indexOf("~") !== -1) {
      let data = line.split(`~`);
      if (!data[0]) data[0] = "";
      if (!data[1]) data[1] = "";
      if (!data[2]) data[2] = "";
      if (!data[3]) data[3] = "";
      if (!data[4]) data[4] = "";

      if (!data[4]) data[4] = "";
      menuStruct.push({
        title: data[0].trim(),
        slug: data[1].trim(),
        parent: data[2].trim(),
        sort: parseInt(data[3]),
        filter: data[4],
      });
    }
  });
  // sort by sort value
  menuStruct.sort((a, b) => (a.sort > b.sort ? 1 : -1));

  // build items and parents
  menus["items"] = [];
  menus["parents"] = [];

  menuStruct.forEach((menu) => {
    if (!menus["items"][menu.slug]) {
      menus["items"][menu.slug] = [];
    }
    if (!menus["parents"][menu.parent]) {
      menus["parents"][menu.parent] = [];
    }
    menus["items"][menu.slug] = menu;
    menus["parents"][menu.parent].push(menu.slug);
  });
  // render menu
  if (type === "ul") {
    return (
      <div key={v4()} className={`content menu`}>
        {createMenu(start, menus, 0, maxDepth, linkClassName, className, type)}
      </div>
    );
  } else {
    return createMenu(
      start,
      menus,
      0,
      maxDepth,
      linkClassName,
      className,
      type
    );
  }
}

MenuRender.propTypes = {
  start: PropTypes.string,
  depth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  data: PropTypes.string,
};

function createMenu(
  parent,
  menu,
  depth,
  maxDepth,
  linkClassName = "",
  className = "",
  type = "ul"
) {
  if (menu["parents"][parent] && menu["parents"][parent].length) {
    if (depth >= maxDepth) return <></>;
    depth++;

    if (type === "ul") {
      return (
        <>
          <ul key={v4()} className={className}>
            {menu["parents"][parent].map((child) => {
              if (menu["parents"][child]) {
                // parent
                // if you are getting an error
                // Warning: Each child in a list should have a unique "key" prop.
                // make sure you have keys in the React.Fragment

                return (
                  <React.Fragment key={v4()}>
                    <li>
                      <Link
                        to={menu["items"][child].slug}
                        title={menu["items"][child].title}
                        className={linkClassName}
                      >
                        {menu["items"][child].title}
                      </Link>
                      {createMenu(
                        child,
                        menu,
                        depth,
                        maxDepth,
                        linkClassName,
                        className,
                        type
                      )}
                    </li>
                  </React.Fragment>
                );
              } else {
                // not a parent

                if (menu["items"][child].title !== "-----") {
                  return (
                    <React.Fragment key={v4()}>
                      <li>
                        <Link
                          to={menu["items"][child].slug}
                          title={menu["items"][child].title}
                          className={linkClassName}
                        >
                          {menu["items"][child].title}
                        </Link>
                      </li>
                    </React.Fragment>
                  );
                } else {
                  // empty because no behavior associated with command in this menu.type
                  return <React.Fragment key={v4()}></React.Fragment>;
                }
              }
            })}
          </ul>
        </>
      );
    } else {
      // navbar
      return (
        <>
          {menu["parents"][parent].map((child) => {
            if (menu["parents"][child]) {
              // parent
              // if you are getting an error
              // Warning: Each child in a list should have a unique "key" prop.
              // make sure you have keys in the React.Fragment

              return (
                <React.Fragment key={v4()}>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <EnhancedLink
                      to={menu["items"][child].slug}
                      title={menu["items"][child].title}
                      className="navbar-link"
                    >
                      {menu["items"][child].title}
                    </EnhancedLink>
                    <div className="navbar-dropdown">
                      {createMenu(
                        child,
                        menu,
                        depth,
                        maxDepth,
                        linkClassName,
                        className,
                        type
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              // not a parent

              if (menu["items"][child].filter) {
                return <React.Fragment key={v4()}></React.Fragment>;
              } else if (menu["items"][child].title === "-----") {
                return (
                  <React.Fragment key={v4()}>
                    <hr className="navbar-divider" />
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={v4()}>
                    <EnhancedLink
                      to={menu["items"][child].slug}
                      title={menu["items"][child].title}
                      className="navbar-item"
                    >
                      {menu["items"][child].title}
                    </EnhancedLink>
                  </React.Fragment>
                );
              }
            }
          })}
        </>
      );
    }
  } else {
    return <></>;
  }
}
