import * as React from "react";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { Link } from "gatsby";

function isBrowser() {
  return typeof window !== "undefined";
}

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });
  // by enabling the class here, i'm using useEfect() to detect page loading

  React.useEffect(() => {
    if (key === "consentCookieHidden" && value === false) {
      document.getElementById("modal").className = "modal is-active";
    }
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = () => {
  const location = useLocation();

  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    "consentCookieHidden"
  );

  const EnableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true";
    document.cookie = "gatsby-modals=true";
    setBannerHidden(true);
  };

  const DisableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=false";
    document.cookie = "gatsby-modals=false";
    setBannerHidden(true);
  };

  if (location.pathname === "/policies/cookie-policy") {
    return <></>;
  } else {
    return (
      <>
        {!bannerHidden && (
          <>
            <div className="modal" id="modal">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">We Value Your Privacy</p>
                  </header>
                  <div className="card-content pl-4">
                    <div className="columns is-multiline">
                      <div className="column is-12">
                        <div className="content">
                          <p>
                            We use cookies to enhance your browsing experience,
                            and analyze our traffic. By clicking "Accept All",
                            you consent to our use of cookies.
                          </p>
                          <p>
                            More information is available in our{" "}
                            <Link
                              to="/policies/cookie-policy"
                              title="Cookie Policy"
                            >
                              Cookie Policy
                            </Link>
                            .
                          </p>
                        </div>
                      </div>
                      <div className="column is-12">
                        <div className="is-pulled-right">
                          <div className="columns">
                            <div className="column is-6">
                              <label
                                className="label"
                                name="enable"
                                htmlFor="enable"
                              >
                                <span className="is-hidden">Accept</span>
                                <button
                                  onClick={EnableAnalytics}
                                  className=""
                                  name="enable"
                                  id="enable"
                                >
                                  Accept&nbsp;All
                                </button>
                              </label>
                            </div>
                            <div className="column is-6">
                              <label
                                className="label"
                                name="disable"
                                htmlFor="disable"
                              >
                                <span className="is-hidden">
                                  Do Not Accept Cookies
                                </span>
                                <button
                                  onClick={DisableAnalytics}
                                  className=""
                                  name="disable"
                                  id="disable"
                                >
                                  Reject&nbsp;All
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br clear="all" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
              ></button>
            </div>
          </>
        )}
      </>
    );
  }
};

export default CookieConsent;
