// components/Nav/EnhancedLink.js
import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
const isActive =
  (className) =>
  ({ isPartiallyCurrent }) => ({
    className: className + (isPartiallyCurrent ? " active" : ""),
  });

const EnhancedLink = ({ className, children, ...props }) => {
  let { pathname } = useLocation();

  if (
    pathname.match(
      new RegExp("^\\/(most-popular|tags|categories|category\\/|tag\\/)", "gi")
    ) &&
    children === "Blog"
  ) {
    // hijacked enhanced link
    return (
      <Link className={`${className} active`.trim()} {...props}>
        {children}
      </Link>
    );
  } else {
    // normal enhanced link
    return (
      <Link getProps={isActive(className)} {...props}>
        {children}
      </Link>
    );
  }
};

export default EnhancedLink;
