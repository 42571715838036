import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

export default function FullWidthImage(props) {
  const {
    height = 600,
    img,
    title,
    subheading,
    detail = "",
    imgPosition = "top left",
    showTitle = true,
    className = "",
  } = props;

  let hiddenState = "is-hidden";
  if (showTitle) {
    hiddenState = "";
  }

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
        }}
      >
        {img && !img.images ? (
          <>
            <img
              src={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                height: height,
                width: "100%",
              }}
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          </>
        ) : (
          <>
            <GatsbyImage
              image={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                maxHeight: height,
              }}
              layout="fullWidth"
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          </>
        )}

        <>
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                className={`has-text-weight-bold page-title ${hiddenState} ${className}`}
              >
                {title}
              </h1>
            )}
            {subheading && (
              <h2 className={`page-subtitle ${hiddenState} ${className}`}>
                {subheading}
              </h2>
            )}
            {detail ? (
              <h3 className={`page-detail ${hiddenState} ${className}`}>
                {detail}
              </h3>
            ) : null}
          </div>
        </>
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
