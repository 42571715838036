import * as React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SocialIcons from "./SocialIcons";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import Menu from "./Menu";

const FooterTemplate = class extends React.Component {
  render() {
    const { data } = this.props;

    const social = data.markdownRemark.frontmatter.social;
    const gatsbyConfig = data.site.siteMetadata;
    const siteConfig = data.markdownRemark.frontmatter.site;
    const siteLogos = data.markdownRemark.frontmatter.logos;

    const phonenumber = siteConfig.phone;

    let name = null;

    siteConfig.name ? (name = siteConfig.name) : (name = gatsbyConfig.title);

    return (
      <footer className="footer has-background-black has-text-white-ter">
        {siteLogos && siteLogos.logoBlack ? (
          <div className="content has-text-centered">
            <Link to="/" className="footer-item" title="Logo">
              <figure className="image is-inline-block footer-logo">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: siteLogos.logoBlack,
                    alt: name,
                    style: {
                      width: "400px",
                    },
                  }}
                />
              </figure>
            </Link>
          </div>
        ) : null}
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <Menu
                    id="Top"
                    start="."
                    depth="1"
                    className="menu-list"
                    linkClassName="navbar-item"
                  />
                </section>
              </div>
              <div className="column is-4">
                <section className="menu">
                  <Menu
                    id="Lower"
                    start="."
                    depth="1"
                    className="menu-list"
                    linkClassName="navbar-item"
                  />
                </section>
              </div>
              <div className="column is-4 phone-social">
                <section>
                  <div className="mt-2 pt-2">
                    <span className="tel" value={phonenumber}>
                      <span className="type is-hidden">Work</span>{" "}
                      <span className="value">
                        <a
                          href={`tel:${phonenumber}`}
                          title={`Call ${phonenumber}`}
                          className="has-text-white"
                        >
                          {phonenumber}
                        </a>
                      </span>
                    </span>
                  </div>
                  <SocialIcons
                    socialIconClass="is-inline social-icon has-text-white p0 ml-2"
                    social={social}
                  />
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query LowerNav {
          site {
            siteMetadata {
              title
            }
          }
          markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              site {
                name
                url
                phone
              }
              logos {
                logoWhite {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                logoBlack {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
              }
              social {
                linkedin
                twitter
                facebook
                instagram
                youtube
                google
                soundcloud
                reddit
                email
              }
            }
          }
        }
      `}
      render={(data, count) => <FooterTemplate data={data} count={count} />}
    />
  );
}
