import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site: markdownRemark(
          frontmatter: {
            advanced: {
              configKey: { eq: "site" }
              templateKey: { eq: "configuration" }
            }
          }
        ) {
          frontmatter {
            site {
              name
              summary
              keywords
            }
            style {
              css
              fonts {
                hero
                headers
                content
              }
            }
          }
        }
      }
    `
  );
  return site.frontmatter;
};

export default useSiteMetadata;
