import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
import DisplayDate from "./DisplayDate";
export default function DisplayContentHeader(props) {
  let {
    slug = "",
    grandparent = "",
    grandparentSlug = "",
    breadcrumbs = "",
    parent = "",
    parentSlug = "",
    parentSubtitle = "",
    title,
    titleClass = "",
    subtitle,
    subtitleClass = "",
    date = "",
    endDate = "",
    relatedPerson = "",
    relatedPersonType = "",
    relatedPersonSlug = "",
    breadcrumbWeight = "bold",
    breadcrumbSize = 5,
    weight = "bold",
    size = 3,
  } = props;

  if (!title) {
    return <></>;
  }

  if (breadcrumbs) {
    if (breadcrumbs.parent) parent = breadcrumbs.parent;
    if (breadcrumbs.parentSlug) parentSlug = breadcrumbs.parentSlug;
    if (breadcrumbs.parentSubtitle) parentSubtitle = breadcrumbs.parentSubtitle;
    if (breadcrumbs.grandparent) grandparent = breadcrumbs.grandparent;
    if (breadcrumbs.grandparentSlug)
      grandparentSlug = breadcrumbs.grandparentSlug;
  }

  const last = title.charAt(title.length - 1);
  let colon = "";

  if (last !== "?" && last !== "!" && last !== ":") {
    colon = ":";
  }

  let breadcrumbRender = [];

  if (grandparent) {
    if (grandparentSlug) {
      breadcrumbRender.push(
        <>
          <Link to={grandparentSlug} title={grandparent}>
            <h2
              className={`has-text-weight-${breadcrumbWeight} is-size-${breadcrumbSize} is-inline mb-0`}
              id={`grandparent___${kebabCase(grandparent)}`}
            >
              {grandparent}
            </h2>
          </Link>
        </>
      );
    } else {
      breadcrumbRender.push(
        <>
          <h2
            className={`has-text-weight-${breadcrumbWeight} is-size-${breadcrumbSize} is-inline mb-0`}
            id={`grandparent___${kebabCase(grandparent)}`}
          >
            {grandparent}
          </h2>
        </>
      );
    }
  }
  if (parent) {
    if (parentSlug) {
      breadcrumbRender.push(
        <>
          <Link to={parentSlug} title={parent}>
            <h2
              className={`has-text-weight-${breadcrumbWeight} is-size-${breadcrumbSize} is-inline mb-0`}
              id={`parent___${kebabCase(parent)}`}
            >
              {parent}
            </h2>
          </Link>
        </>
      );
    } else {
      breadcrumbRender.push(
        <>
          <h2
            className={`has-text-weight-${breadcrumbWeight} is-size-${breadcrumbSize} is-inline mb-0`}
            id={`parent___${kebabCase(parent)}`}
          >
            {parent}
          </h2>
        </>
      );
    }
  }

  if (parentSubtitle) {
    breadcrumbRender.push(<>parentSubtitle</>);
  }

  return (
    <React.Fragment>
      <header>
        <div className="display-content-header">
          {breadcrumbRender && breadcrumbRender.length ? (
            <>
              <nav
                className="breadcrumb has-succeeds-separator pb-0 mb-0"
                aria-label="breadcrumbs"
              >
                <ul className="ml-0 pl-0">
                  {breadcrumbRender.map((breadcrumb) => {
                    return (
                      <React.Fragment key={v4()}>
                        <li>{breadcrumb}</li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </nav>
            </>
          ) : null}

          {titleClass ? (
            <h2
              className={`title is-size-${size} has-text-weight-${weight} mt-0 mb-2 ${titleClass} ${`title___${kebabCase(
                title
              )}`}`}
              id={`title___${kebabCase(title)}`}
            >
              {slug ? (
                <>
                  <Link to={slug} title={title}>
                    {title}
                  </Link>
                </>
              ) : (
                <>
                  {title}
                  {colon}
                </>
              )}
            </h2>
          ) : (
            <h2
              className={`title is-size-${size} has-text-weight-${weight} mt-0 mb-2 ${`title___${kebabCase(
                title
              )}`}`}
              id={`title___${kebabCase(title)}`}
            >
              {slug ? (
                <>
                  <Link to={slug} title={title}>
                    {title}
                  </Link>
                </>
              ) : (
                <>
                  {title}
                  {colon}
                </>
              )}
            </h2>
          )}
          {subtitle ? (
            <h5
              className={`sub-title is-size-${
                size + 2
              } ${subtitleClass}has-text-weight-${weight} mt-4`}
              id={`subtitle___${kebabCase(subtitle)}`}
            >
              {subtitle}
            </h5>
          ) : null}

          {date ? (
            <h6
              className={`title is-size-6 has-text-weight-${weight} mt-2 mb-2`}
              id={`date___${kebabCase(title)}`}
            >
              {endDate ? (
                <span>
                  <DisplayDate date={date} /> - <DisplayDate date={endDate} />
                </span>
              ) : (
                <span>
                  <DisplayDate date={date} />
                </span>
              )}
            </h6>
          ) : null}

          {relatedPerson
            ? renderRelatedPerson(
                relatedPerson,
                relatedPersonType,
                relatedPersonSlug
              )
            : null}
        </div>
      </header>
    </React.Fragment>
  );
}

DisplayContentHeader.propTypes = {
  parent: PropTypes.string,
  parentSlug: PropTypes.string,
  parentSubtitle: PropTypes.string,
  grandparent: PropTypes.string,
  grandparentSlug: PropTypes.string,
  breadcrumbs: PropTypes.object,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  relatedPerson: PropTypes.string,
  relatedPersonType: PropTypes.string,
  relatedPersonSlug: PropTypes.string,
};

function renderRelatedPerson(
  relatedPerson = "",
  relatedPersonType = "",
  relatedPersonSlug = ""
) {
  if (relatedPerson) {
    let thisRelated = "";

    if (relatedPersonSlug) {
      thisRelated = (
        <Link to={relatedPersonSlug} title={relatedPerson} className="c-inh">
          {relatedPerson}
        </Link>
      );
    } else {
      thisRelated = relatedPerson;
    }

    if (relatedPersonType) {
      return (
        <div className="content">
          <h2
            className="title is-size-6 has-text-weight-normal mt-0"
            id={`related___${kebabCase(thisRelated)}`}
          >
            {relatedPersonType}: {thisRelated}
          </h2>
        </div>
      );
    } else {
      return (
        <div className="content">
          <h2
            className="title is-size-6 has-text-weight-normal mt-0"
            id={`related___${kebabCase(thisRelated)}`}
          >
            {thisRelated}
          </h2>
        </div>
      );
    }
  }
}
