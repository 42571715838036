import * as React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import EnhancedLink from "./EnhancedLink";
import MenuRender from "./MenuRender";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

FontAwesomeIcon.autoAddCss = false;

const NavbarTemplate = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger() {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  }

  render() {
    const { data, arg } = this.props;
    console.log(data);
    let menu_id = "Top",
      maxDepth = 99,
      selectedMenu = [],
      unfilteredMenus = [];

    if (arg && arg.id) menu_id = arg.id;
    if (arg && arg.depth) maxDepth = arg.depth;

    let siteName = data.site.frontmatter.site.name;
    let sitePhone = data.site.frontmatter.site.phone;
    const siteLogos = data.site.frontmatter.logos;

    if (menu_id) {
      unfilteredMenus = data.markdownRemark.frontmatter.menus;

      selectedMenu = unfilteredMenus.filter((menu) => {
        if (menu.id === menu_id) {
          return true;
        } else {
          return false;
        }
      });

      if (selectedMenu.length) {
        selectedMenu = selectedMenu[0];
      } else {
        selectedMenu = unfilteredMenus[0];
      }
    } else {
      selectedMenu = unfilteredMenus[0];
    }

    let menu = selectedMenu.data.trim();
    menu = menu.replace(new RegExp(/\\~/, "gi"), "~");

    // render menu

    return (
      <div className="navbar-wrapper">
        <nav
          className="navbar is-black"
          role="navigation"
          aria-label="top-level-main-navigation"
        >
          <div className="container">
            <div className="navbar-brand mt-0 p-4">
              {siteLogos && siteLogos.logoBlack ? (
                <EnhancedLink to="/" className="navbar-logo" title="Logo">
                  <figure className="image navbar-logo">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: siteLogos.logoBlack,
                        alt: siteName,
                        style: {
                          width: "200px",
                        },
                      }}
                    />
                  </figure>
                </EnhancedLink>
              ) : null}
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                role="menuitem"
                tabIndex={0}
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                style={{ marginTop: ".75rem" }}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-start has-text-left">
                <MenuRender data={menu} start="." depth={maxDepth} type="div" />
              </div>
              <div className="navbar-end has-text-centered">
                <a className="navbar-item" href={`tel:${sitePhone}`}>
                  <FontAwesomeIcon icon={faPhone} style={{ height: "20px" }} />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

Menu.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  id: PropTypes.string,
};

export default function Menu(arg) {
  return (
    <StaticQuery
      query={graphql`
        query NavbarMenus {
          markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "menus" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              menus {
                id
                data
              }
            }
          }
          site: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              site {
                name
                phone
              }
              logos {
                logoWhite {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                logoBlack {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <NavbarTemplate data={data} count={count} arg={arg} />
      )}
    />
  );
}
