import * as React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import MenuRender from "./MenuRender";

const MenuTemplate = class extends React.Component {
  render() {
    const { data, arg } = this.props;
    let menu_id = "",
      start = ".",
      className = "",
      linkClassName = "",
      maxDepth = 99,
      buffer = [],
      selectedMenu = [],
      unfilteredMenus = [],
      menu;

    if (arg && arg.id) menu_id = arg.id;

    if (arg && arg.start) start = arg.start;
    if (arg && arg.depth) maxDepth = arg.depth;

    if (arg && arg.className) className = arg.className;
    if (arg && arg.linkClassName) linkClassName = arg.linkClassName;

    if (menu_id) {
      unfilteredMenus = data.markdownRemark.frontmatter.menus;
      if (menu_id === "ALL") {
        unfilteredMenus.forEach((menu) => {
          buffer.push(menu.data);
        });
        selectedMenu = {};
        selectedMenu.data = buffer.join("\n");
      } else {
        selectedMenu = unfilteredMenus.filter((menu) => {
          if (menu.id === menu_id) {
            return true;
          } else {
            return false;
          }
        });

        if (selectedMenu.length) {
          selectedMenu = selectedMenu[0];
        } else {
          selectedMenu = unfilteredMenus[0];
        }
      }
    } else {
      selectedMenu = unfilteredMenus[0];
    }

    menu = selectedMenu.data.trim();

    if (!menu) {
      return <>Menu Error.</>;
    } else {
      return (
        <MenuRender
          data={menu}
          start={start}
          depth={maxDepth}
          className={className}
          linkClassName={linkClassName}
        />
      );
    }
  }
};

Menu.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  id: PropTypes.string,
};

export default function Menu(arg) {
  return (
    <StaticQuery
      query={graphql`
        query Menus {
          markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "menus" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              menus {
                id
                data
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <MenuTemplate data={data} count={count} arg={arg} />
      )}
    />
  );
}
