import * as React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faInstagram,
  faFacebook,
  faTwitter,
  faSoundcloud,
  faYoutube,
  faVimeo,
  faReddit,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
FontAwesomeIcon.autoAddCss = false;

const SocialIcons = ({
  youtube,
  instagram,
  facebook,
  twitter,
  soundcloud,
  google,
  reddit,
  vimeo,
  linkedin,
  github,
  email,
  socialClass,
  social,
  socialIconClass = "is-inline social-icon",
}) => {
  let thisClass = "social";
  if (socialClass) thisClass = `${socialClass} social`;

  if (social) {
    if (social.youtube) youtube = social.youtube;
    if (social.instagram) instagram = social.instagram;
    if (social.facebook) facebook = social.facebook;

    if (social.reddit) reddit = social.reddit;
    if (social.twitter) twitter = social.twitter;
    if (social.soundcloud) soundcloud = social.soundcloud;
    if (social.google) google = social.google;
    if (social.vimeo) vimeo = social.vimeo;
    if (social.linkedin) linkedin = social.linkedin;
    if (social.github) github = social.github;
    if (social.email) email = social.email;
  }

  let socialCnt = 0;

  if (youtube) socialCnt++;
  if (instagram) socialCnt++;
  if (facebook) socialCnt++;
  if (soundcloud) socialCnt++;
  if (google) socialCnt++;
  if (reddit) socialCnt++;
  if (vimeo) socialCnt++;
  if (email) socialCnt++;
  if (linkedin) socialCnt++;

  if (socialCnt) {
    return (
      <div className={thisClass}>
        {linkedin ? (
          <a title="Facebook" href={linkedin}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faLinkedin} className={socialIconClass} />
            </span>
          </a>
        ) : null}
        {github ? (
          <a title="Github" href={github}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faGithub} className={socialIconClass} />
            </span>
          </a>
        ) : null}
        {facebook ? (
          <a title="Facebook" href={facebook}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faFacebook} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {twitter ? (
          <a title="Twitter" href={twitter}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faTwitter} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {instagram ? (
          <a title="Instagram" href={instagram}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faInstagram} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {google ? (
          <a title="Google" href={google}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faGoogle} className={socialIconClass} />
            </span>
          </a>
        ) : null}
        {youtube ? (
          <a title="YouTube" href={youtube}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faYoutube} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {vimeo ? (
          <a title="Vimeo" href={vimeo}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faVimeo} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {reddit ? (
          <a title="Reddit" href={reddit}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faReddit} className={socialIconClass} />
            </span>
          </a>
        ) : null}

        {soundcloud ? (
          <a title="Soundcloud" href={soundcloud}>
            <span className="icon is-large">
              <FontAwesomeIcon
                icon={faSoundcloud}
                className={socialIconClass}
              />
            </span>
          </a>
        ) : null}

        {email ? (
          <a title="Contact" href={email}>
            <span className="icon is-large">
              <FontAwesomeIcon icon={faEnvelope} className={socialIconClass} />
            </span>
          </a>
        ) : null}
      </div>
    );
  } else {
    return <></>;
  }
};

SocialIcons.propTypes = {
  youtube: PropTypes.string,
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  soundcloud: PropTypes.string,
  socialClass: PropTypes.string,
};

export default SocialIcons;
