import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const DisplayDate = ({ date }) => {
  // check to see if the value passed is a string
  if (typeof date !== "string") {
    // if it isn't, can we convert it safely?
    // eslint-disable-next-line
    if (date == String(date)) {
      date = String(date);
    } else {
      return date;
    }
  }

  if (date.indexOf(" (Pacific Standard Time)") !== -1) {
    date = String(date).replace(" (Pacific Standard Time)", "");
  }

  date = new Date(date);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getMonth()]; // January
  const day = date.getDate(); // 2
  const year = date.getFullYear(); // 2019

  date = `${month} ${day}${nth(day)} ${year}`;

  return <React.Fragment key={v4()}>{date}</React.Fragment>;
};

DisplayDate.propTypes = {
  date: PropTypes.string,
};

export default DisplayDate;

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
